import {useQuery} from '@tanstack/react-query';
import {Settings} from './settings';
import {User} from '../auth/user';
import {CssTheme} from '../ui/themes/css-theme';
import {Localization} from '../i18n/localization';
import {apiClient, queryClient} from '../http/query-client';
import {MetaTag} from '../seo/meta-tag';
import {Role} from '../auth/role';

export interface BootstrapData {
  themes: {all: CssTheme[]; selectedThemeId: number | null};
  settings: Settings;
  user: User | null;
  guest_role: Role | null;
  language: string;
  i18n: Localization;
  default_meta_tags: MetaTag[];
  show_cookie_notice: boolean;
}

const initialBootstrapData = (
  window.bootstrapData ? JSON.parse(atob(window.bootstrapData)) : undefined
) as BootstrapData;

const queryKey = ['bootstrapData'];

function decodeBootstrapData(data: string | BootstrapData): BootstrapData {
  return typeof data === 'string' ? JSON.parse(atob(data)) : data;
}

queryClient.setQueryData(queryKey, initialBootstrapData);

export function getBootstrapData(): BootstrapData {
  return queryClient.getQueryData(['bootstrapData'])!;
}

export function setBootstrapData(data: string | BootstrapData) {
  queryClient.setQueryData<BootstrapData>(
    queryKey,
    typeof data === 'string' ? decodeBootstrapData(data) : data
  );
}

export function mergeBootstrapData(partialData: Partial<BootstrapData>) {
  setBootstrapData({
    ...initialBootstrapData,
    ...partialData,
  });
}

export function invalidateBootstrapData() {
  queryClient.invalidateQueries(queryKey);
}

export default function useBootstrapData() {
  const {data} = useQuery(queryKey, fetchBootstrapData, {
    staleTime: Infinity,
    keepPreviousData: true,
    initialData: initialBootstrapData,
  });
  return data;
}

const fetchBootstrapData = async (): Promise<BootstrapData> => {
  return apiClient.get('bootstrap-data').then(response => {
    return decodeBootstrapData(response.data.data);
  });
};

export function useSettings(): Settings {
  return useBootstrapData().settings;
}
